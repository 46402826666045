:root{
  --pokeYellow: #ffcb05;
  --pokeRed: #ff4848;
  --dbWhite:#dbdbdb;
}

h1,h2,h3,h4,h5,h6{
  color: var(--pokeYellow);
}

.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.App-logo-small {
  height: 20vmin;
  width: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    max-width: 50vw;
    max-height: 50vh;
  }
  .App-logo-small {
    animation: App-logo-spin infinite 5s linear;
  }
}

header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 3vh 10vh;
}

.App-link {
  color: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: 'VT323', monospace;
  font-size: 1.7rem;
  background-color: #282c34;
  color: #ffcb05;
  /* color: #61dafb; */
}

section {
	justify-content: space-between;
	align-items: center;
	margin: 40px 40px;
	padding: 10px 10px;
  width: 100%;
}

form{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.Main-Content {
  max-width: 80vw;
  margin-left: 10vw;
  height: 50vh;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

input{
  display: flex;
	justify-content: center;
	height: 50px;
	width: 80%;
	max-width: 500px;
	margin: 3px;
	border-bottom: solid #dbdbdb 2px;
	border-left: solid #dbdbdb 2px;
	border-top: solid #ff4848 2px;
  border-right: solid #ff4848 2px;
	border-radius: 12px;
	background-color: #34363c;
  font-family: 'VT323', monospace;
	font-size: 1.2em;
  text-align: center;
	align-items: center;
  color: #f9cb33;
}

button{
  font-family: 'VT323', monospace;
  display: flex;
  justify-content: center;
  text-align: center;
	align-items: center;
  height: 80px;
  width: 80px;
  margin: 5px;
  color: #dbdbdb;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 60px;
  background-color: var(--pokeRed);
  border-bottom: solid var(--dbWhite) 5px;
	border-left: solid var(--dbWhite) 5px;
	border-top: solid var(--pokeRed) 5px;
  border-right: solid var(--pokeRed) 5px;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.22, 0.6, 0.85, 0.6);
  cursor: pointer;
}

button:active {
  box-shadow: inset #000000d4 1px 1px 9px 3px;
  background-color: var(--dbWhite);
  color: var(--pokeRed);
  border-color: var(--pokeRed);
  transition-duration: 500ms;
  transition:auto;
}

div.Results{
  border: solid #00000042 5px;
  border-radius: 25px;
  display: block;
  width: 40%;
  padding: 40px;
  margin: 50px;
  background-color: #00000042;
}

.title{
  display: block;
  position:relative;
}

@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.PokeSearchSection {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}