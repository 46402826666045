body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS Anthony */
:root {
	--pokeYellow: #ffcb05;
	--pokeRed: #ff4848;
	--dbWhite: #dbdbdb;
}

div[class*='container_pokeSlot'] {
	display: flex;
	justify-content: center;
	height: 70px;
	width: 400px;
	color: grey;
	align-items: center;
	border-bottom: solid var(--dbWhite) 4px;
	border-left: solid var(--dbWhite) 4px;
	border-top: solid var(--pokeRed) 4px;
	border-right: solid var(--pokeRed) 4px;
	border-radius: 45px;
	margin: 3px;
	background-color: #34363c;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	transition: 1s;
}

div.active, .active > div {
	color: var(--pokeYellow);
}

div[class*='container_pokeSlot'].active {
	background-color: black;
    border-bottom: solid #dbdbdb87 4px;
    border-left: solid #dbdbdb6e 4px;
    border-top: solid #ff484873 4px;
    border-right: solid #ff484880 4px;
	border-radius: 45px;
	transition: 1s;
}

/* div[class*='container_pokeSlot']:hover {
	background-color: #524e4d;
} */

div[class*='container_pokeSlot'] > svg {
	max-height: 461px;
	position: absolute;
	z-index: 1;
	rotate: -90deg;
	padding-top: 9px;
	pointer-events: none;
}

div[class*='container_pokeSlot'].active > svg > g#svgBallBottom {
	transition-duration: 500ms;
	transform: translateY(0px);
}

#svgBallBottom {
	transition-duration: 500ms;
	transform: translateY(-680px);
	cursor: unset;
}


/* User Screen CSS options  */
section#UserLogin {
    transform: translateY(0px);
    transition-duration: 1s;
}

section#UserLogin.hide {
    transition-duration: 1s;
    transform: translateY(-110VH) scale(0.01);
}

#CloseButton {
    width: 40px;
    height: 40px;
    font-weight: bold;
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #000000;
    border-radius: 25%;
    scale: 0.8;
    border-width: 3px;
}

div.GetUsersContainer,
header div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
	padding: 10px;
}

div.GetUsersContainer > button,
header button {
	width: 160px;
}
/* End of User screen CSS options */

/* Media Tag for Smaller Screens */
@media screen and (max-width: 1250px) {
	section#c01s01 {
		padding: 0px;
		margin: 0px;
		width: 100%;
	}
}

@media screen and (max-width: 900px) {
	div[class*='container_pokeSlot']:hover {
		background-color: #34363c;
	}
	.PokeSearchSection {
		flex-direction: column;
	}
	div[class*='container_pokeSlot'] {
		width: 90%;
		max-width: 300px;
		padding: 0px;
		margin: 5px;
		height: 50px;
	}
	div[class*='container_pokeSlot'] > svg {
		display: none;
	}
	div.selectorSpace {
		width: 90%;
		padding: 0px;
		margin: 0px;
	}
}
/* End of Media */

input[id*='input-pokeSlot'] {
	height: inherit;
	width: 90%;
	background-color: #34363c00;
	font-family: 'VT323', monospace;
	font-size: 1.2em;
	text-align: center;
	margin: 0;
	border: none;
	z-index: 20;
	text-transform: capitalize;
}

.active input[id*='input-pokeSlot'] {
	color: white;
}

input:focus-visible {
	border: none;
	outline: none;
	height: inherit;
}

form:has(input[id*='input-pokeSlot']) {
	margin-top: 0;
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: end;
	flex-wrap: nowrap;
	width: 100%;
	justify-content: flex-end;
}

.pokemonParty-Container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr 2fr 1fr 2fr;
	grid-column-gap: 0px;
	grid-row-gap: 10px;
	justify-items: center;
}

#partyGridZone1 {
	grid-area: 1 / 1 / 2 / 2;
}
#partyGridZone2 {
	grid-area: 1 / 2 / 2 / 3;
}
#partyGridZone3 {
	grid-area: 1 / 3 / 2 / 4;
}
#partyGridZone4 {
	grid-area: 2 / 1 / 3 / 2;
	width: 90%;
    background-color: #80808024;
    border-radius: 25px;
}
#partyGridZone5 {
	grid-area: 2 / 2 / 3 / 3;
	width: 90%;
    background-color: #80808024;
    border-radius: 25px;
}
#partyGridZone6 {
	grid-area: 2 / 3 / 3 / 4;
	width: 90%;
    background-color: #80808024;
    border-radius: 25px;
}
#partyGridZone7 {
	grid-area: 3 / 1 / 4 / 2;
}
#partyGridZone8 {
	grid-area: 3 / 2 / 4 / 3;
}
#partyGridZone9 {
	grid-area: 3 / 3 / 4 / 4;
}
#partyGridZone10 {
	grid-area: 4 / 1 / 5 / 2;
	width: 90%;
    background-color: #80808024;
    border-radius: 25px;
}
#partyGridZone11 {
	grid-area: 4 / 2 / 5 / 3;
	width: 90%;
    background-color: #80808024;
    border-radius: 25px;
}
#partyGridZone12 {
	grid-area: 4 / 3 / 5 / 4;
	width: 90%;
    background-color: #80808024;
    border-radius: 25px;
}

div.active>svg>g>circle#innerBallButton {
    fill: rgb(255, 80, 80);
}

g#PokeBallButton {
	cursor: pointer;
	pointer-events: auto;
	z-index: 10;
}

div.active>svg>g>circle#outerBallButton {
    fill: rgb(244, 170, 170);
}

section#UserLogin {
    margin: 3vh 10vh;
    background: black;
    display: flex;
    position: fixed;
    z-index: 300;
    border: solid 4px black;
    border-radius: 24px;
    padding: 25px;
    width: 80%;
    height: fit-content;
    border-bottom: solid var(--dbWhite) 4px;
    border-left: solid var(--dbWhite) 4px;
    border-top: solid var(--pokeRed) 4px;
    border-right: solid var(--pokeRed) 4px;
    box-shadow: -9px 14px 13px 0px #130a0a96;
    top: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
